<template>
	<div class="list category_news_article" style="border: 0;">
		<div class="content_wrapper" v-html="data"></div>
	</div>
</template>

<script>

	export default {
		
		data() {
			return {
				data: '测试内容'
			}
		}
	}
</script>

<style>
	
</style>